<script setup lang="ts">

import ListHostels from '@/Pages/the-slp/components/the-shortcodes/ListHostels.vue'
import CardHostels from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostels.vue'
import CompareTable from '@/Pages/the-slp/components/the-shortcodes/CompareTable.vue'
import SliderHostels from '@/Pages/the-slp/components/the-shortcodes/SliderHostels.vue'
import CheapHostelsList from '@/Pages/the-slp/components/the-shortcodes/CheapHostelsList.vue'
import CheapHostelsCard from '@/Pages/the-slp/components/the-shortcodes/CheapHostelsCard.vue'
import PrivateHostels from '@/Pages/the-slp/components/the-shortcodes/PrivateHostels.vue'
import WhenBook from '@/Pages/the-slp/components/the-shortcodes/WhenBook.vue'
import TopHostel from '@/Pages/the-slp/components/the-shortcodes/TopHostel.vue'
import FAQ from '@/Pages/the-slp/components/the-shortcodes/FAQ.vue'
import AveragePriceGraph from '@/Pages/the-slp/components/the-shortcodes/average-price-graph/AveragePriceGraph.vue'
import IntroWhyFemaleHostels from '@/Pages/the-slp/components/the-shortcodes/IntroWhyFemaleHostels.vue'

const props = defineProps<{
    content: string
    shortCodesData: {
        shortCode: string
        props: []
    }[]
}>()

const dynamicComponents: Record<string, unknown> = {
    ListHostels,
    CardHostels,
    CompareTable,
    SliderHostels,
    CheapHostelsList,
    CheapHostelsCard,
    PrivateHostels,
    WhenBook,
    TopHostel,
    FAQ,
    AveragePriceGraph,
    IntroWhyFemaleHostels
}

const htmlContent = props.content
    // .split(/<p>\[slp:(\w+)\]<\/p>/)
    // todo: in the end could be '&nbsp;'
    .split(/<p>\[slp:(\w+)\](?:&nbsp;|\u00A0)?<\/p>/)
    .filter(str => !['', '\n'].includes(str))
    .map(item => ({
        type: item in dynamicComponents ? 'component' : 'html',
        content: item
    }))

const shortCodesProps = new Map

props.shortCodesData.forEach(item => shortCodesProps.set(item.shortCode, item.props))

</script>

<template>
    <template v-for="(piece, index) in htmlContent" :key="index">
        <div v-if="piece.type === 'html'" v-html="piece.content" />
        <component :is="dynamicComponents[piece.content]" v-else :="shortCodesProps.get(piece.content)" />
    </template>
</template>

<style scoped lang="scss">

</style>